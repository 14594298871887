<template>
  <div class="app-box">
    <x-form-box>
      <x-form-input label="招待时间" disable v-model="xhrData.entertainDate"></x-form-input>
      <x-form-select label="招待类型" disable :slots="EntertainTypeEnum" :bindValue="xhrData.entertainType"></x-form-select>
      <x-form-textarea label="招待对象公司及岗位" disable v-model="xhrData.entertainObject"></x-form-textarea>
      <x-form-input label="招待和陪同人数" disable v-model="xhrData.personNum"></x-form-input>
      <x-form-input  label="招待金额" disable v-model="xhrData.amount"></x-form-input>
      <x-form-input  label="费用申请人" placeholder="" disable v-model="xhrData.name"></x-form-input>
    </x-form-box>
    <!-- <button class="client-no-data" @click="printEvent">打印</button> -->
    <!-- <div style="overflow-y:0;overflow-x:0;"> -->
    <div style="width:0;height:0;">
      <!-- <button class="client-no-data" @click="pdfPrint2" size="mini">打印22</button>
      <button class="client-no-data" @click="pdfPrint3" size="mini">打印33</button> -->
      <div>
        <pdf
          ref="pdf"
          :src="pdfUrl">
        </pdf>
      </div>
    </div>
    <div class="split-box"></div>
    <i v-if="!isEdit" class="edit-icon" @click="editEvent"></i>
    <x-footer-box type="print" :isDisabledBtn=false @printEvent="printEvent"></x-footer-box>
  </div>
</template>

<script>
import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import formSelect from "@/components/formControl/formSelect";
import formPosition from "@/components/formControl/formPosition";
import formTextArea from "@/components/formControl/formTextArea";
import formDrugsList from "@/components/formControl/formDrugsList";
import formDeviceList from "@/components/formControl/formDeviceList";
import formTag from "@/components/formControl/formTag";
import formImages from "@/components/formControl/formImages";
import footerBox from "@/components/footerBox";
import { getEntertain } from "@/api/entertainList";
import { getPrintData } from "@/api/entertainList";
import { getCustomer } from "@/api/customerCenter";
import pdf from "vue-pdf";

export default {
  data () {
    return {
      isLeader: JSON.parse(sessionStorage.getItem("token")).isLeader,
      isEdit: this.$route.params.isEdit,
      xhrData: {
        staffVo: {}
      },
      pdfUrl:"",
      customerData: null,
      shopOpenEnum: [{
        flex: 1,
        values: [{
          code: 1,
          name: "是"
        }, {
          code: 0,
          name: "否"
        }],
        textAlign: "center",
        defaultIndex: 0
      }],
      EntertainTypeEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }],
      VisitOtherChanceEnum: []
    };
  },
  created () {
    document.title = "";
    document.title = "电子招待清单详情";
    this.setEnum();
  },
  methods: {
    setEnum () {
      const configJson = JSON.parse(sessionStorage.getItem("configJson"));
      Object.keys(configJson.EntertainTypeEnum).forEach(key => {
        this.EntertainTypeEnum[0].values.push({
          code: key,
          name: configJson.EntertainTypeEnum[key]
        });
      });
      // Object.keys(configJson.VisitOtherChanceEnum).forEach(key => {
      //   this.VisitOtherChanceEnum.push({
      //     code: key,
      //     name: configJson.VisitOtherChanceEnum[key],
      //     check: false
      //   });
      // });
      this.getData();
    },
    getData () {
      getEntertain({
        id: this.$route.params.id
      }).then(xhr => {
        this.xhrData = xhr.data;
        this.xhrData.saleId = xhr.data.saleId;
        this.xhrData.name = xhr.data.name;
        //this.xhrData.scopeIsOkBegin = parseInt(this.xhrData.scopeIsOkBegin);
        //this.xhrData.scopeIsOkEnd = parseInt(this.xhrData.scopeIsOkEnd);
        //this.getCustomerData();
      });

      getPrintData({id:this.$route.params.id}).then(res=>{
        console.log("开始打印------getPrintData-");
        const blob = new Blob([res],{type:"application/pdf"});
        console.log("开始打印------getPrintData-" + blob);
        const date = (new Date()).getTime();
        console.log("开始打印------getPrintData--------1");
        const ifr = document.createElement("iframe");
        ifr.style.frameborder = "no";
        ifr.style.display = "none";
        ifr.style.pageBreakBefore = "always";
        console.log("开始打印------getPrintData--------2");
        ifr.setAttribute("id", "printPdf" + date);
        ifr.setAttribute("name", "printPdf" + date);
        console.log("开始打印------getPrintData--------3");
        ifr.src = window.URL.createObjectURL(blob);
        // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
        this.pdfUrl= pdf.createLoadingTask({url:ifr.src});
        //this.pdfUrl = ifr.src;
        // document.body.appendChild(ifr);
        // this.doPrint("printPdf" + date);
        // console.log("地址:" + ifr.src);
        // ifr.src = window.URL.createObjectURL(blob);
        // window.URL.revokeObjectURL(ifr.src) ;// 释放URL 对象
        // this.pdfPrint2();
      });

    },
    getCustomerData () {
      getCustomer({
        customerId: this.xhrData.customerId
      }).then(xhr => {
        this.customerData = xhr.data;
      });
    },
    editEvent () {
      this.$router.push({
        path: `/entertainList/edit/${this.$route.params.id}`
      });
    },
    // getPrint(){
    //   console.log("开始打印------printBtnClick-");
    //   getPrintData({id:this.$route.params.id}).then(res=>{
    //     console.log("开始打印------getPrintData-");
    //     const blob = new Blob([res],{type:"application/pdf"});
    //     const date = (new Date()).getTime();
    //     console.log("开始打印------getPrintData--------1");
    //     const ifr = document.createElement("iframe");
    //     ifr.style.frameborder = "no";
    //     ifr.style.display = "none";
    //     ifr.style.pageBreakBefore = "always";
    //     console.log("开始打印------getPrintData--------2");
    //     ifr.setAttribute("id", "printPdf" + date);
    //     ifr.setAttribute("name", "printPdf" + date);
    //     console.log("开始打印------getPrintData--------3");
    //     ifr.src = window.URL.createObjectURL(blob);
    //     this.pdfUrl = ifr.src;
    //     document.body.appendChild(ifr);
    //     //this.doPrint("printPdf" + date);
    //     console.log("地址:" + ifr.src);
    //     ifr.src = window.URL.createObjectURL(blob);
    //     window.URL.revokeObjectURL(ifr.src) ;// 释放URL 对象
    //     //this.pdfPrint2();
    //   });
    // },
    // doPrint(val) {
    //   const ordonnance = document.getElementById(val).contentWindow;
    //   setTimeout(() => {
    //     console.log("开始打印-------doPrint");
    //     ordonnance.print();
    //   }, 100);
    // },
    printEvent(){
      //console.log("pdfPrint2----------1");
      //alert("printEvent begin");
      this.$refs.pdf.print();
      //alert("printEvent end");
    }
    // // pdfPrint3(){
    // //   console.log("pdfPrint3----------1");
    // //   print({
    // //     printable:this.pdfUrl,
    // //     type:"pdf",
    // //     showModal: false
    // //   });
    // // },
    // pdfError(error) {
    //   console.error(error);
    // },
    // // printEvent1(){
    // //   console.log("开始打印------printBtnClick-");
    // //   getPrintData({id:this.$route.params.id}).then(res=>{
    // //     console.log("开始打印------getPrintData-");
    // //     const blob = new Blob([res],{type:"application/pdf"});
    // //     const date = (new Date()).getTime();
    // //     console.log("开始打印------getPrintData--------1");
    // //     const ifr = document.createElement("iframe");
    // //     ifr.style.frameborder = "no";
    // //     ifr.style.display = "none";
    // //     ifr.style.pageBreakBefore = "always";
    // //     console.log("开始打印------getPrintData--------2");
    // //     ifr.setAttribute("id", "printPdf" + date);
    // //     ifr.setAttribute("name", "printPdf" + date);
    // //     console.log("开始打印------getPrintData--------3");
    // //     ifr.src = window.URL.createObjectURL(blob);
    // //     console.log("地址" + ifr.src);
    // //     document.body.appendChild(ifr);
    // //     this.doPrint("printPdf" + date);
    // //     window.URL.revokeObjectURL(ifr.src) ;// 释放URL 对象
    // //   });
    // }
  },
  components: {
    [formBox.name]: formBox,
    [formInput.name]: formInput,
    [formSelect.name]: formSelect,
    [formPosition.name]: formPosition,
    [formTextArea.name]: formTextArea,
    [formDrugsList.name]: formDrugsList,
    [formDeviceList.name]: formDeviceList,
    [footerBox.name]: footerBox,
    [formTag.name]: formTag,
    [formImages.name]: formImages,
    pdf
  }
};
</script>

<style scoped>
.client-box {
  display: flex;
  margin-bottom: 0.1rem;
}
.client-box label {
  display: inline-table;
  padding: 0.02rem 0.06rem;
  border-radius: 0.02rem;
  background-color: rgba(43, 130, 247, 0.1);
  line-height: 1;
  font-size: 0.12rem;
  color: #2b82f7;
}
.client-box p {
  line-height: 0.16rem;
  width: 2.7rem;
  margin-left: 0.08rem;
  font-size: 0.14rem;
  color: #737885;
}
.split-box {
  height: 0.4rem;
}
.client-no-data {
  margin: auto;
  background-color: #9fc7fc;
  box-shadow: 0 0.02rem 0.02rem 0 #d0e8ff;
}
</style>
